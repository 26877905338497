/* Custom Scripts
 */

// Buttons
const buttons = () => {
  // Button: Burger Menu Trigger
  const burgerLink = document.querySelector(".burger");
  const top_bar = document.querySelector(".top-bar");
  const mainElement = document.querySelector("main");
  const footerElement = document.querySelector("footer");
  const nav = document.querySelector("nav");
  const search = document.querySelector("#search");
  const search_form = document.querySelector(".search-form");

  burgerLink.addEventListener("click", function (event) {
    event.preventDefault();

    if (top_bar.classList.contains("open")) {
      top_bar.classList.remove("open");
      mainElement.classList.remove("open");
      footerElement.classList.remove("open");
    } else {
      top_bar.classList.add("open");
      mainElement.classList.add("open");
      footerElement.classList.add("open");
    }
  });

  // Hide mobile nav menu when activated: Add event listeners to child links within nav
  const navLinks = Array.from(nav.getElementsByTagName("a"));
  navLinks.forEach(function (link) {
    link.addEventListener("click", function () {
      top_bar.classList.remove("open");
    });
  });

  // Mobile menu: handle submenu display on touch devices
  const menuItemsWithChildren = document.querySelectorAll(
    ".menu-item-has-children"
  );

  // Detect if device supports touch
  const isTouchDevice = () => {
    return (('ontouchstart' in window) ||
      (navigator.maxTouchPoints > 0) ||
      (navigator.msMaxTouchPoints > 0));
  };

  menuItemsWithChildren.forEach((menuItem) => {
    const subMenu = menuItem.querySelector(".sub-menu");
    const topBar = document.querySelector(".top-bar");
    let rafId;

    if (!subMenu) return; // Skip if no sub-menu found

    const updateMargin = () => {
      rafId = requestAnimationFrame(() => {
        const subMenuHeight = subMenu.scrollHeight;
        menuItem.style.marginBlockEnd = `${subMenuHeight}px`;
      });
    };

    const resetMargin = () => {
      if (rafId) {
        cancelAnimationFrame(rafId);
      }
      menuItem.style.marginBlockEnd = "0";
    };

    // Handle mobile menu interactions
    menuItem.addEventListener("click", (e) => {
      if (topBar.classList.contains("open")) {
        const clickedLink = e.target.closest('a');
        if (clickedLink && clickedLink.parentElement === menuItem) {
          e.preventDefault();
          e.stopPropagation();

          if (menuItem.classList.contains("submenu-open")) {
            // If submenu is open, close it
            menuItem.classList.remove("submenu-open");
            resetMargin();
          } else {
            // Close any other open submenus
            menuItemsWithChildren.forEach((item) => {
              if (item !== menuItem) {
                item.classList.remove("submenu-open");
                item.style.marginBlockEnd = "0";
              }
            });
            // Open this submenu
            menuItem.classList.add("submenu-open");
            updateMargin();
          }
        }
      }
    });

    // Handle desktop hover
    menuItem.addEventListener("mouseenter", () => {
      if (topBar.classList.contains("open")) {
        menuItem.classList.add("submenu-open");
        updateMargin();
      }
    });
    
    menuItem.addEventListener("mouseleave", () => {
      if (topBar.classList.contains("open")) {
        menuItem.classList.remove("submenu-open");
        resetMargin();
      }
    });

    // Handle window resize
    window.addEventListener("resize", () => {
      if (!topBar.classList.contains("open")) {
        resetMargin();
      }
    });
  });

  // Search form
  search.addEventListener("click", function (event) {
    event.preventDefault();

    if (search_form.classList.contains("open")) {
      search_form.classList.remove("open");
    } else {
      search_form.classList.add("open");
    }
  });
};

export { buttons };

// Accordions (toggle open/close + close all other accordions when one is opened)
const accordions = () => {
  document.addEventListener("DOMContentLoaded", () => {
    const accordions = document.querySelectorAll(".accordion-heading");

    accordions.forEach((accordion) => {
      accordion.addEventListener("click", () => {
        const currentAccordion = accordion.closest(".accordion-item");
        const content = currentAccordion.querySelector(".accordion-content");

        // Close all other accordions
        accordions.forEach((otherAccordion) => {
          if (otherAccordion !== accordion) {
            const otherAccordionItem =
              otherAccordion.closest(".accordion-item");
            const otherContent =
              otherAccordionItem.querySelector(".accordion-content");

            otherAccordionItem.classList.remove("is-active");
            otherContent.style.maxHeight = "0px";
          }
        });

        // Toggle the clicked accordion
        if (currentAccordion.classList.contains("is-active")) {
          currentAccordion.classList.remove("is-active");
          content.style.maxHeight = "0px";
        } else {
          currentAccordion.classList.add("is-active");
          content.style.maxHeight = `${content.scrollHeight + 40}px`;
        }
      });
    });
  });
};
export { accordions };

// Tabs Manager
const tabsManager = () => {
  const tabButtons = document.querySelectorAll(".tab");
  const tabGroups = document.querySelectorAll(".tabs-group");

  const setActiveTab = (clickedTab) => {
    // Remove active class from all tabs and tab groups
    tabButtons.forEach((tab) => {
      tab.classList.remove("is-active");
      tab.setAttribute("aria-selected", "false");
    });
    tabGroups.forEach((group) => {
      group.classList.remove("is-active");
      group.classList.add("is-hidden");
    });

    // Add active class to clicked tab and its corresponding content
    clickedTab.classList.add("is-active");
    clickedTab.setAttribute("aria-selected", "true");

    const targetId = clickedTab.dataset.tab;
    const targetContent = document.querySelector(
      `[data-tab-content="${targetId}"]`,
    );
    targetContent.classList.add("is-active");
    targetContent.classList.remove("is-hidden");

    // If there's any open quickview, close it
    const openQuickview = targetContent.querySelector(
      ".-full-width:not(.is-hidden)",
    );
    if (openQuickview) {
      const toggleParent = openQuickview.previousElementSibling;
      closeQuickView(toggleParent, toggleParent, openQuickview);
    }
  };

  tabButtons.forEach((tab) => {
    tab.addEventListener("click", (e) => {
      setActiveTab(e.currentTarget);
    });
  });
};

export { tabsManager };

// Cards: Quick view
const quickview = () => {
  const quickViewButtons = document.querySelectorAll("[data-quick-view]");
  const closeButtons = document.querySelectorAll("[data-close]");
  const fullwidthCards = document.querySelectorAll(".-full-width");
  let toggle;
  let toggleParent;
  let fullwidth;

  const openQuickView = (toggle, toggleParent, fullwidth) => {
    toggle.setAttribute("aria-expanded", "true");
    toggleParent.classList.toggle("is-active");
    fullwidth.classList.toggle("is-hidden");
    // Make fullwidth card keyboard focusable.
    fullwidth.setAttribute("tabIndex", "0");
  };

  const closeQuickView = (toggle, toggleParent, fullwidth) => {
    toggle.setAttribute("aria-expanded", "false");
    toggleParent.classList.toggle("is-active");
    fullwidth.classList.toggle("is-hidden");
    fullwidth.removeAttribute("tabIndex");
  };

  quickViewButtons.forEach((quickView) => {
    // Add appropriate ARIA attributes for "toggle" behaviour.
    fullwidth = quickView.nextElementSibling;
    quickView.setAttribute("aria-expanded", "false");
    quickView.setAttribute("aria-controls", fullwidth.id);

    quickView.addEventListener("click", (e) => {
      toggle = e.currentTarget;
      toggleParent = toggle;
      fullwidth = toggle.nextElementSibling;

      // Open (or close) fullwidth card.
      if (toggle.getAttribute("aria-expanded") === "false") {
        // Do we have another fullwidth card already open? If so, close it.
        fullwidthCards.forEach((fullwidthOpen) => {
          if (!fullwidthOpen.classList.contains("is-hidden")) {
            toggleParentOpen = fullwidthOpen.previousElementSibling;
            toggleOpen = toggleParentOpen;

            closeQuickView(toggleOpen, toggleParentOpen, fullwidthOpen);
          }
        });

        openQuickView(toggle, toggleParent, fullwidth);
      } else {
        closeQuickView(toggle, toggleParent, fullwidth);
      }
    });
  });

  closeButtons.forEach((close) => {
    close.addEventListener("click", (e) => {
      fullwidth = close.closest(".-full-width");
      toggleParent = fullwidth.previousElementSibling;
      toggle = toggleParent;

      closeQuickView(toggle, toggleParent, fullwidth);
      toggle.focus(); // Return keyboard focus to "toggle" button.
    });
  });
};

export { quickview };

// CAROUSEL
const carousel = () => {
  const track = document.querySelector(".carousel__track");
  if (!track) return; // Guard clause if no carousel exists

  const slides = Array.from(track.children);
  const dotsNav = document.querySelector(".carousel__nav");
  const dots = Array.from(dotsNav.children);

  const moveToSlide = (track, currentSlide, targetSlide) => {
    if (!targetSlide) return;
    const targetIndex = slides.findIndex((slide) => slide === targetSlide);
    const moveAmount = targetIndex * -100; // Use percentage instead of pixels
    track.style.transform = `translateX(${moveAmount}vw)`;

    // Remove current-slide from all slides first
    slides.forEach((slide) => slide.classList.remove("current-slide"));
    targetSlide.classList.add("current-slide");

    // Update dots to match the current slide
    dots.forEach((dot) => dot.classList.remove("current-slide"));
    dots[targetIndex].classList.add("current-slide");
  };

  const updateDots = (currentDot, targetDot) => {
    if (!targetDot) return;
    dots.forEach((dot) => dot.classList.remove("current-slide"));
    targetDot.classList.add("current-slide");
  };

  const moveToNextSlide = () => {
    const currentSlide = track.querySelector(".carousel__slide.current-slide");
    const currentDot = dotsNav.querySelector(
      ".carousel__indicator.current-slide",
    );

    if (!currentSlide || !currentDot) {
      moveToSlide(track, null, slides[0]);
      updateDots(null, dots[0]);
      return;
    }

    // Get the index of the current slide and calculate next index
    const currentIndex = Array.from(track.children).indexOf(currentSlide);
    // Reverse the order by going backwards through the array
    const nextIndex = currentIndex === 0 ? slides.length - 1 : currentIndex - 1;

    const nextSlide = slides[nextIndex];
    const nextDot = dots[nextIndex];

    moveToSlide(track, currentSlide, nextSlide);
    updateDots(currentDot, nextDot);
  };

  // Initialize first slide
  moveToSlide(track, null, slides[0]);
  updateDots(null, dots[0]);

  // Set up automatic sliding
  let autoSlideInterval = setInterval(moveToNextSlide, 5000); // 5 seconds

  // Pause automatic sliding when user interacts with carousel
  track.addEventListener("mouseenter", () => {
    clearInterval(autoSlideInterval);
  });

  track.addEventListener("mouseleave", () => {
    clearInterval(autoSlideInterval);
    autoSlideInterval = setInterval(moveToNextSlide, 5000);
  });

  // Click indicators
  dotsNav.addEventListener("click", (e) => {
    const targetDot = e.target.closest(".carousel__indicator");
    if (!targetDot) return;

    const currentSlide = track.querySelector(".carousel__slide.current-slide");
    const targetIndex = Array.from(dotsNav.children).indexOf(targetDot);
    const targetSlide = slides[targetIndex];

    moveToSlide(track, currentSlide, targetSlide);

    // Reset interval when manually changing slides
    clearInterval(autoSlideInterval);
    autoSlideInterval = setInterval(moveToNextSlide, 5000);
  });
};

// Initialize carousel when DOM is loaded
document.addEventListener("DOMContentLoaded", carousel);

export { carousel };

// console.log(carousel);
// alert('test!!!');
